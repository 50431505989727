import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import YoutubeVideoCard from 'components/Advanced_User/VPN_AVM_Fritzbox/YoutubeVideoCard';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "VPN Tunnel",
  "path": "/Advanced_User/VPN_AVM_Fritzbox/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "General information for the Fritz.box VPN function",
  "image": "./AU_SearchThumb_VPN.png",
  "social": "/images/Search/AU_SearchThumb_VPN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_VPN_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='VPN Tunnel' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='General information for the Fritz.box VPN function' image='/images/Search/AU_SearchThumb_VPN.png' twitter='/images/Search/AU_SearchThumb_VPN.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/VPN_AVM_Fritzbox/' locationFR='/fr/Advanced_User/VPN_AVM_Fritzbox/' crumbLabel="VPN" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "vpn-tunnel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vpn-tunnel",
        "aria-label": "vpn tunnel permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VPN Tunnel`}</h1>
    <h2 {...{
      "id": "general-information-for-the-fritzbox-vpn-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#general-information-for-the-fritzbox-vpn-function",
        "aria-label": "general information for the fritzbox vpn function permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General information for the Fritz.box VPN function`}</h2>
    <p>{`In order for the Fritz! Box to become the VPN server, first check `}<strong parentName="p">{`System / Update`}</strong>{`, if the latest firmware version is installed. Then, make sure that it is permanently connected to the Internet and that the connection is not disconnected when it is inactive. In order to be able to access the router or the VPN server on the way, you must know its address. Since the address changes every 24h, however, you could theoretically register with a DDNS provider to get a fixed Internet address. However, since your INSTAR camera already uses a built-in DDNS service, you can use the address under Network DDNS in the web interface of the camera.`}</p>
    <div style={{
      maxWidth: '915px',
      margin: '2.5rem 0 2.5rem 0',
      boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%'
    }}>
    <YoutubeVideoCard mdxType="YoutubeVideoCard" />
    </div>
    <h3 {...{
      "id": "1-preparations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-preparations",
        "aria-label": "1 preparations permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1 Preparations`}</h3>
    <p>{`First go to the AVM services page at `}<a parentName="p" {...{
        "href": "https://avm.de/service/vpn/uebersicht/"
      }}>{`AVM-VPN`}</a>{` to get the latest version of Fritz! Remote Access for your Windows (Either 32-bit or 64-bit) and then install it by default. The program takes all your security settings and saves them in a configuration file, which you then only have to import into the VPN remote.`}</p>
    <h3 {...{
      "id": "2-vpn-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-vpn-settings",
        "aria-label": "2 vpn settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2 VPN Settings`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "329px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/23f0e058aa3493f50809eb8ba6097a0d/004a5/VPN-Fritzbox-7490de_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQoz5XNX2vTUBgG8PfSD+J30Ytl3ngzta649VN4JwhS/EO3G5kXA0EEYWhno6ltmTRbmqZp0jYLrtuUzrkkZ0nPOTnJmnTaRtKB7ErY7+Lh4YGXF67vPr62lYPPGSgvwpcM8Asg3E3z0wJU7gF/B8QsKEsgPQA1B/ISyLPyNQs7WXj4Kre8dn/xZSa7cvvW1qMb23lOejpfz3Ni/qb4JO16gTNXud7zOeMFZxQ4Y5UzC3PqM667Ar/frbv7P+xWLamWok57kxf0Zut8FI2CkP+wqSvq+VkU0XBwKKL9jyPXicMJ+9UdfnsTsTH49Yp47NTb60yXiN4sCkKtVvUZwxiXSiWhXMYEE0r7u9XmDo+Q7QehYx8df29QP4D4/eue1DAlOaoWiSY7hDqWhTFGCFmWhRDyPM+2TjrtjqZ1B4OfQRgc7PVVtYsJhiH/dlQphuWNs0qRIZuyIGDM9302y38wHhJCLi+UUthzbdUwJb3XNExN64j1bUVRGg251Wo76Vv3gjfjXoIxhv7RoeegU8dxEbpYT1Npkv9KPx+cDJLJNLm68XicHk//TKZXlCRJHMd/AQ6D6Krq9/tTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/23f0e058aa3493f50809eb8ba6097a0d/e4706/VPN-Fritzbox-7490de_00.avif 230w", "/en/static/23f0e058aa3493f50809eb8ba6097a0d/810b9/VPN-Fritzbox-7490de_00.avif 329w"],
              "sizes": "(max-width: 329px) 100vw, 329px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/23f0e058aa3493f50809eb8ba6097a0d/a0b58/VPN-Fritzbox-7490de_00.webp 230w", "/en/static/23f0e058aa3493f50809eb8ba6097a0d/d3f24/VPN-Fritzbox-7490de_00.webp 329w"],
              "sizes": "(max-width: 329px) 100vw, 329px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/23f0e058aa3493f50809eb8ba6097a0d/81c8e/VPN-Fritzbox-7490de_00.png 230w", "/en/static/23f0e058aa3493f50809eb8ba6097a0d/004a5/VPN-Fritzbox-7490de_00.png 329w"],
              "sizes": "(max-width: 329px) 100vw, 329px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/23f0e058aa3493f50809eb8ba6097a0d/004a5/VPN-Fritzbox-7490de_00.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the FRITZ! Box remote access program and click on `}<em parentName="p">{`New`}</em>{`".`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "518px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/333065905a08b23fdc9b977dbcc84612/6b9fd/VPN-Fritzbox-7490de_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADCklEQVQ4y5WSy28bBRCHfeePQByoWqGgShwA9cCh4hTEmZ5Q6SEC9cClopEaqRWlFY9TqzQhPNxKbQIJLUnVpCFu08RBcTCu8/Db68TYqb3rXXtf3l3HdrwfyjopVEhAR/o00mg0mt/Mz9fz6wCHHn7CoWA/hxfOcnihn5cfneHIYj9Hk5c4mr78LKm/sV97NfkZPfGL9EQv4FuSi6xUJVarVRbKJYYmhxgOjFF0HBq2happHpquU1NVDNNE13V0w3hab7aauEDHdfF9WBykLz3IvWKG6+kULw2O8NrENPl6HaVUIplIImQybAo5ErEE2XQGpVLB0HS0moqu6bRbbbxwwdebGeCN+VOcnr+GP77OK2OTnAkLRBXD6+nwf8LFdbv4Ti5epm/8PCNzNzgVmOHkgwAz4Q3uLy1Deg0r9hhrI4ITe4wTj2JvRGjs5XiU5uoKrlbz5HIw8IOZj5kf7yN8632Ojd/gxdEfeX3iJ679/AMEZ1Ee3kOenaT6yxS1wF0qM3eQpm8jzk5SvzsG5YI30O10tfhO3HqP0Egvl84f59zNz+mf8NN35zorhSy0d8kLAsV8HlWR0RQFo1ZDlWVqkuTdUhRF2u3uDb0N3/zyIm+fG+Dst1fIpeZwKiGccpBdp0yz7SIIAptbeVRt78ual3XD9LLnAFVFram0Wq3uhu987efdET/ZzBw7xiqyHKYqh9hpSLRau+SEHIKQwzAMjz3LmKb5DIZu0Nrf0vfW1WF6r35HcP0BhrKCo0WoVpbZcUSeNzzJH41OsZ5/QrZU5tOp+wQ3HmGrv+HY4n5T56kl/gtvw6qpYukmO5ZNdrvM8S++Z7sQhKbU/d7BwE7nL/5lqG81nWQz/weRwhInBq/wwumvmE4tU6orB5Z9Ltm+SCLOtrhFMBniwu0Ao+EYvz/ZZkup0rBt6paNramYuTT6ZhZTSGHJEqZlUa/X/4FP0hTqholl2tBs0LYtbMPA1HXkSoWiKFGJr1G+Ocyafwhx9BsqoUWisTipRBJRlCgUCqTTGc+TfwJGRR/z59rO3gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/333065905a08b23fdc9b977dbcc84612/e4706/VPN-Fritzbox-7490de_01.avif 230w", "/en/static/333065905a08b23fdc9b977dbcc84612/d1af7/VPN-Fritzbox-7490de_01.avif 460w", "/en/static/333065905a08b23fdc9b977dbcc84612/0d37b/VPN-Fritzbox-7490de_01.avif 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/333065905a08b23fdc9b977dbcc84612/a0b58/VPN-Fritzbox-7490de_01.webp 230w", "/en/static/333065905a08b23fdc9b977dbcc84612/bc10c/VPN-Fritzbox-7490de_01.webp 460w", "/en/static/333065905a08b23fdc9b977dbcc84612/c29d2/VPN-Fritzbox-7490de_01.webp 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/333065905a08b23fdc9b977dbcc84612/81c8e/VPN-Fritzbox-7490de_01.png 230w", "/en/static/333065905a08b23fdc9b977dbcc84612/08a84/VPN-Fritzbox-7490de_01.png 460w", "/en/static/333065905a08b23fdc9b977dbcc84612/6b9fd/VPN-Fritzbox-7490de_01.png 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/333065905a08b23fdc9b977dbcc84612/6b9fd/VPN-Fritzbox-7490de_01.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the `}<em parentName="p">{`Set up remote access for a user`}</em>{` option and click `}<em parentName="p">{`Next`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "517px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d376921bc6f6f090632933a85c41989c/fa2f5/VPN-Fritzbox-7490de_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACHUlEQVQ4y6WTbU/TUBTH+4kYwc/mKwnxBfDKb6C8MRp8SzCAEBQcyRCnQraybpQ+d710a3tvx0NE2c+sbKBhBh9u8stpb87595z/SbWJnWkmy9NM7T5mameG0vYjBnel8jSl2iwT9VlK9Tkm9fnfMMfk4TwPmk+YOphDyy/PaRpNPu7tUa1WMY9MTNPEMJq4jkOzYWCax1xd9Rmcfr9fwCgCspezuP6CN5/fop1ffeX75TdklhWMkv7m9E57PHv+kJn3W2hRGtPpJthBiB8JnCDA8nwCMXgOsR2nwPM8HNfFtm0sy8YZ3h0fW4TtEJkIRJKgRVmXxHMJavu0G3VCvVYgDJ2woeO7Hp7n4/t+EcMgLISu3z0cx0UIgVQ5Sko0EXn03q3Q/VShs7dDVNkm/lAmrlbolTc4bemoiwtypcjzfCxKKbIsK9DESYDaWsPZXMFaf425tszR6hLG6hLxxjK5aZD2emRpelM0DinltWCUdVCZJHBsAsfBty18y6LtOoh2G5Xn10VSFkX3USxFSYUctq5UjlTqhtHX5SjeL9gpzLxvlH8SlH841n91eMNgKSOGno7LuxWUdzvMht5lAz/PzpGnZ0XM8vwXC36eTgu6gjRJSZLkDmmS0E1T0qhNVz8gNup06vskgUeSZrd56W39cMty7DLikxNqRgu/uku8+BT95QLi1QKiss2X+iEN/ZA4jvFcj1brqPhjfgBvXEachSCToQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d376921bc6f6f090632933a85c41989c/e4706/VPN-Fritzbox-7490de_02.avif 230w", "/en/static/d376921bc6f6f090632933a85c41989c/d1af7/VPN-Fritzbox-7490de_02.avif 460w", "/en/static/d376921bc6f6f090632933a85c41989c/b4e52/VPN-Fritzbox-7490de_02.avif 517w"],
              "sizes": "(max-width: 517px) 100vw, 517px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d376921bc6f6f090632933a85c41989c/a0b58/VPN-Fritzbox-7490de_02.webp 230w", "/en/static/d376921bc6f6f090632933a85c41989c/bc10c/VPN-Fritzbox-7490de_02.webp 460w", "/en/static/d376921bc6f6f090632933a85c41989c/7f59e/VPN-Fritzbox-7490de_02.webp 517w"],
              "sizes": "(max-width: 517px) 100vw, 517px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d376921bc6f6f090632933a85c41989c/81c8e/VPN-Fritzbox-7490de_02.png 230w", "/en/static/d376921bc6f6f090632933a85c41989c/08a84/VPN-Fritzbox-7490de_02.png 460w", "/en/static/d376921bc6f6f090632933a85c41989c/fa2f5/VPN-Fritzbox-7490de_02.png 517w"],
              "sizes": "(max-width: 517px) 100vw, 517px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d376921bc6f6f090632933a85c41989c/fa2f5/VPN-Fritzbox-7490de_02.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the option `}<em parentName="p">{`PC with FRITZ! Remote Access`}</em>{` and click on `}<em parentName="p">{`Next`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "514px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/81489724afb13755999b00e1d88090f8/dea13/VPN-Fritzbox-7490de_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKklEQVQ4y62S3U/TUBTA+4ch+7d89UE0kfjiq8aYGDXGRxNBEja+HGMw2NZ2W9exwj7abrb33q2AbIOfaTeIJICGeJPfPefcnJx7vrT57QUeZZ+Syj8nlV2YknuW2I/MReaMF8yZi8xXX95Lqv6KVHkRjcklzVqDQnYHY69ErWhgFIrouwe4zRbHtUNcp819Z3x6xsev71jJr6KNxmPOzk4RQiDlFTKR48kYuOTi8oLReMT56Pwm57+YTMaEMuTN+8c82UijKaXwghCn49LyfJxOl8NWm6Oui9PuJHr81hMSeXKKiE4S+Sfx21AFCCnR4kyCnU389Dd66yv01r7jppfwMst46SXc1SX8zDIimyHKbzLMbzLIrSO311C5dVQ2w2D3ByKcVqYpt0N/YwXbtmnWLZyGTdO2cRoNVBjQPnKwKiZWpULV0DHLZbqtY1QYIn72kyCD3S1ky0EMhmjS9xAbK7ilfbxS4Rq3VEBYJmFVJ6iUCavGta7qFaJmnejQInJshtk0sttGDAZoQipUs87QLDKslm8wMItTKqWZXrrWlXGAMooofR9l15L+SSHQ4ksMI0Tc3IcSRUmwZChXkWUYPpxZsNmUJf8TLV7ofyKeqghn8m6/v2cYOyo1XeK4V/Fix7YQt2cYBAH3EgrCbod+cY++WSYoFQg8l0CIW/3vLDn+red56Fad49wW3qfX6F8+EHx+i2+WOdANDN2g3+/TbrWwrDq+7/MbHItD1nGgrEgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81489724afb13755999b00e1d88090f8/e4706/VPN-Fritzbox-7490de_03.avif 230w", "/en/static/81489724afb13755999b00e1d88090f8/d1af7/VPN-Fritzbox-7490de_03.avif 460w", "/en/static/81489724afb13755999b00e1d88090f8/dfeb9/VPN-Fritzbox-7490de_03.avif 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/81489724afb13755999b00e1d88090f8/a0b58/VPN-Fritzbox-7490de_03.webp 230w", "/en/static/81489724afb13755999b00e1d88090f8/bc10c/VPN-Fritzbox-7490de_03.webp 460w", "/en/static/81489724afb13755999b00e1d88090f8/7a333/VPN-Fritzbox-7490de_03.webp 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81489724afb13755999b00e1d88090f8/81c8e/VPN-Fritzbox-7490de_03.png 230w", "/en/static/81489724afb13755999b00e1d88090f8/08a84/VPN-Fritzbox-7490de_03.png 460w", "/en/static/81489724afb13755999b00e1d88090f8/dea13/VPN-Fritzbox-7490de_03.png 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/81489724afb13755999b00e1d88090f8/dea13/VPN-Fritzbox-7490de_03.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter the e-mail address of the user who is to establish VPN connections to the FRITZ! Box, and click on `}<em parentName="p">{`Next`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "513px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/196773773184d6f5520017d29dcda70c/267f6/VPN-Fritzbox-7490de_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.39130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACtklEQVQ4y32S227bRhRF+WWN/FP9gzYpUDQB+lY0QNDkoWjf+tKmsWM5ciPHri6WTYl3kRRFivebqSCS3FVwHAUoUGeANWfmnM2ZPQeUHpw95EH/azoX33DQfyjonD8S+476hAP1CV/MvqOjPL4f9TEH+vd0rr5F4sMOWzUY9S+QhxPUS5npYMLV+RBTVhn3B3iGw+fGtml4/tszXg+6SJvdlvW6IS9ysjyjrEqqqiLPM+qbWuQ3mw/c/nPLdrdlt+d2x2a7YbfbkWYxP/z0JV+97SGVVUWQpNhLHy+McYMQJ1jh+oHIucEKa+Fh2C6OHzD3lgLL9fDCiKJZk9801E1NXpZIRZqQ9k8Iuy+Je4dEJ6+I3vyXpHdE2P3zU311/BL/9e+iVp33qN69oRr0KZIYqbI0ot4h87mNrcywZlPmioKtqsyVGZ5lipwxlQWOplHGoaCIQvLAJ8syqotTSkNFKoMlRfcP4r+OyftdsrfHwklyekTcOxK5luLs5FNcj854L+iLuB6/oz49pPC9ux56noelaRiaitE6MwxMTUObTVFkGVPX0RUFS9exDAPbNNHa1xiG0C9sh6IoyIsSqSpLXMdhNBoznc64vJwwubpGURTB+HLCcDhiMByxXPqEYUQY3RHFsYhxnFCUJXmeI7VTWZZkaYpj2xi6gdk60DRc1xWilqZpSJIY27YxDVNodF1HVVSCYEUURcKl1E5JkrBcLnEcRzCfzzFNS3zs+76ot41v13uNZVlCZ5kWi4VHHMd3B7a3t4vWZftD76nrWsQ239aLLKMsCqqPun29jXuNeLIQf9zcS9ufm4a8vrmL9+iFwzRN+SxZRrYKyFSZzNTIVZk0Csmy/H/10n2u2tvCVcC1quP/fUb684/Mfn1B+ctTVtqM8dU1U3kqerdYLNA0nTAM+RfoGzY/AtXgNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/196773773184d6f5520017d29dcda70c/e4706/VPN-Fritzbox-7490de_04.avif 230w", "/en/static/196773773184d6f5520017d29dcda70c/d1af7/VPN-Fritzbox-7490de_04.avif 460w", "/en/static/196773773184d6f5520017d29dcda70c/b0e3d/VPN-Fritzbox-7490de_04.avif 513w"],
              "sizes": "(max-width: 513px) 100vw, 513px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/196773773184d6f5520017d29dcda70c/a0b58/VPN-Fritzbox-7490de_04.webp 230w", "/en/static/196773773184d6f5520017d29dcda70c/bc10c/VPN-Fritzbox-7490de_04.webp 460w", "/en/static/196773773184d6f5520017d29dcda70c/3be34/VPN-Fritzbox-7490de_04.webp 513w"],
              "sizes": "(max-width: 513px) 100vw, 513px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/196773773184d6f5520017d29dcda70c/81c8e/VPN-Fritzbox-7490de_04.png 230w", "/en/static/196773773184d6f5520017d29dcda70c/08a84/VPN-Fritzbox-7490de_04.png 460w", "/en/static/196773773184d6f5520017d29dcda70c/267f6/VPN-Fritzbox-7490de_04.png 513w"],
              "sizes": "(max-width: 513px) 100vw, 513px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/196773773184d6f5520017d29dcda70c/267f6/VPN-Fritzbox-7490de_04.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter the DDNS address in the input field `}<em parentName="p">{`Name`}</em>{` (see the network interface of the camera under Network - DDNS) and click on `}<em parentName="p">{`Next`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "516px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b13b9b4c47347126a2a3136918cc41c/75dcb/VPN-Fritzbox-7490de_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADLElEQVQ4y32Sa2/jRBSG869o+4/4GawWCVik/QaCpQti2+12C7tf+FABbZO0dZuLE9uxc3ESJ7ZjO04cX5Os0LZ6kF1AWgkY6Zl3zhnNe+ZoprRz84id60/Yq3/KrvCIvZzbx0W81/+S3d4TPtI+Z6f7xX/Te8Ke/pRd+TNK3N1jjCY0ayKqrNLt9OjIKpIo01f7KK0OjuXyf2O7fcfh6QHnUpXS+7v33KUxqWOTeTPWc5e155DMLOKZycb3YJtxn0TcJ+EH3CUhrFNiZ8qzrz/mcfWCUupYhKdvcIUKi5sK8+tz3MszfOGC+fVFsbbLv5G0b9mqIptOk21HZKM0yaT6A3KDbe2cpHFJKZmOWTYEuqaN2uuhDnQ0fUhXH6INhqj9AWpfR59MMSybie0wMi1MxyXM1kQ523dEy4CscUUpMQ1i8YbeaIQktpAlibbYotft0m61MMZj/PmcuefhOg6e4+DOnCJOk4QkjomTlMRzSHPDeDomlerY/gJ9MGBmz/Bcl+nUxDLNwmQVrv7KTTHNh3yug8EAw5gwc12c0ZC1KDwYZoqI6S8YDkdMLQvTtrEdh6llF3F+wLIttG6vYOY4BGGIv1iwCALCNCWY2WTNwtBg077FGI1Q6jUGikJPkhh0ZLrtFkqjUaimyBj9Pr5t4UwMzKGOPR7jGAaT0ZBwMiKrVSgl1pTs4CsWP7/AOfwG9+V3eEf7zA6+xT9+zvz4e/xX+4Sv9ouW/pBqrJtXrBtXbJrXbEWheIxMOCfpdSjFc4+sfon2y1uEF89pvzlG/OkI6e1rmicvuT36kfbrQ0Znp/SMKf3hGN2Y0On26fQe0AY6QZISZ2tKURyTbbYoskK5XEEQbqjXG4WWy1Wq1UsEQaBcqXL26+9cliuUL8pIrTYdRaEjK2iqRrAMiKOIUj7lhGHIarUiCAKC5ZJVsCo0yvP5fpoSpSlhmrFcrUjTlM1mU7Ber4nz7xPHlHKTvwlzwvADgjAidGYEUoOFprCSRULv4ZXzgsUFguAfj1IURfwbeTV/7qEOhli1a7yjZ2gnByxPfmAui0iqVrS6XC6xLAtdH+L7Pn8CUZkgjJ24j2gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b13b9b4c47347126a2a3136918cc41c/e4706/VPN-Fritzbox-7490de_05.avif 230w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/d1af7/VPN-Fritzbox-7490de_05.avif 460w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/8829a/VPN-Fritzbox-7490de_05.avif 516w"],
              "sizes": "(max-width: 516px) 100vw, 516px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b13b9b4c47347126a2a3136918cc41c/a0b58/VPN-Fritzbox-7490de_05.webp 230w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/bc10c/VPN-Fritzbox-7490de_05.webp 460w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/5f588/VPN-Fritzbox-7490de_05.webp 516w"],
              "sizes": "(max-width: 516px) 100vw, 516px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b13b9b4c47347126a2a3136918cc41c/81c8e/VPN-Fritzbox-7490de_05.png 230w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/08a84/VPN-Fritzbox-7490de_05.png 460w", "/en/static/5b13b9b4c47347126a2a3136918cc41c/75dcb/VPN-Fritzbox-7490de_05.png 516w"],
              "sizes": "(max-width: 516px) 100vw, 516px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b13b9b4c47347126a2a3136918cc41c/75dcb/VPN-Fritzbox-7490de_05.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assign an IP address to the computer in the FRITZ! Box network. You can use the standard data if the Fritz box has the factory settings (192.168.178.1). If you enter the data manually, make sure that you do not enter the IP address of the Fritz box, but the address of the network (e.g., 192.168.178.0).`}</p>
    <p>{`If you want to send the entire Internet traffic via your home network, you can activate the function `}<em parentName="p">{`Send all data via the VPN tunnel`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "514px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ddb282676c8aa2b7883f897d8f18798/dea13/VPN-Fritzbox-7490de_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB80lEQVQ4y52Ty27TQBSG/WCheS6WsEgXlG5gg4QQCCRegB0SLLoJwpcotuOxx5cYx20SE4/HSaBqG/9oTi4qKA3QkT6fOXP5/c9NO+p28KDbQfvLMdpdRQets8do6cdoeU/Rcp+gNTjBETs9SJs/Q9s+gYbrBgkLYXW/wjX74LaH82GGbzzBNM2RsggXwxEOlavlD7z/8AYf9U/Qrm6u8fPyEkJWkHWNxXJJg5oN27JqGtw0K4orNLtcFbmo8erdQzw6+wxNSonJZIIkSRDHMcIwRJwkiKIInIeU8zBEFMVIkiG1BwHf9Q+HKc2rygJCyrVglmUwDRN230bPsmCZFnpWD4z51GYYBgzdgOO4cB0Hum7ANC0Yhok0TZHnOWalQFVV0NRHiHVS1zUx30SF2ob5fE6on6/rmz4pCVWvKkE6JFgUBS2NMUauAt+H5zHwgCPwAwwGA8pd1wXzGFEU37E1s4UcbpM4itDv2/C8AWzbga+Wa9vI83MaWJbljtsiW6HfBFXlPuybq/1p+3/YJ6rd191daP/sqCxRiRLVnj28zd8dqoFSQiyWEPMFxUpKat/rcDab4S5KFdXJji9Q+i5EzCF8F7PJmC7yvjnaoQ2fjsdwfI6R3sXo9XP03r5E+uIUU89Gj16NS3d4lGX0HNUT/gWQfEhriJpCAgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ddb282676c8aa2b7883f897d8f18798/e4706/VPN-Fritzbox-7490de_06.avif 230w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/d1af7/VPN-Fritzbox-7490de_06.avif 460w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/dfeb9/VPN-Fritzbox-7490de_06.avif 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ddb282676c8aa2b7883f897d8f18798/a0b58/VPN-Fritzbox-7490de_06.webp 230w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/bc10c/VPN-Fritzbox-7490de_06.webp 460w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/7a333/VPN-Fritzbox-7490de_06.webp 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ddb282676c8aa2b7883f897d8f18798/81c8e/VPN-Fritzbox-7490de_06.png 230w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/08a84/VPN-Fritzbox-7490de_06.png 460w", "/en/static/7ddb282676c8aa2b7883f897d8f18798/dea13/VPN-Fritzbox-7490de_06.png 514w"],
              "sizes": "(max-width: 514px) 100vw, 514px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ddb282676c8aa2b7883f897d8f18798/dea13/VPN-Fritzbox-7490de_06.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this field, leave the default setting and click `}<em parentName="p">{`Finish`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6350171e4d098339f41b8b1483d4dc4a/a3767/VPN-Fritzbox-7490de_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEElEQVQoz+2PXWvCMBiF8/9hf2fCnLir3ezCoeA2GAwUJpq0SdOkafrhM1pldA6H3u/AA+c9eXNIxM3jE+P1nNvVjNH6mbvtggf9xkS9ci87lr2fqjmjz1l/Pk1emKglY7nkdrNgfLzTIVyaU2hHMAErc4okp8kjQRf4xOGUpbKRMknxKiOagqgddqsIpkBtNNlGQWh7ROYcSbojsynGZljvsC7v2SmJ0ik6MxQxUlYVVVMT64oQy6M/5PGYi9U25f1jRSg8RkukVBhj2O/3OOfJc4f3nrquadqWpmn4S8L6EqkS2rb9QVcIBzp/mPn25xDD4VRdNIyHpefKxXD5Uoalv758zfIlEqfP/y+8uvALYeypv8qOvaYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6350171e4d098339f41b8b1483d4dc4a/e4706/VPN-Fritzbox-7490de_07.avif 230w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/d1af7/VPN-Fritzbox-7490de_07.avif 460w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/7f308/VPN-Fritzbox-7490de_07.avif 920w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/dbbeb/VPN-Fritzbox-7490de_07.avif 1210w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6350171e4d098339f41b8b1483d4dc4a/a0b58/VPN-Fritzbox-7490de_07.webp 230w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/bc10c/VPN-Fritzbox-7490de_07.webp 460w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/966d8/VPN-Fritzbox-7490de_07.webp 920w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/efe91/VPN-Fritzbox-7490de_07.webp 1210w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6350171e4d098339f41b8b1483d4dc4a/81c8e/VPN-Fritzbox-7490de_07.png 230w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/08a84/VPN-Fritzbox-7490de_07.png 460w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/c0255/VPN-Fritzbox-7490de_07.png 920w", "/en/static/6350171e4d098339f41b8b1483d4dc4a/a3767/VPN-Fritzbox-7490de_07.png 1210w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6350171e4d098339f41b8b1483d4dc4a/c0255/VPN-Fritzbox-7490de_07.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now FRITZ! Box remote access set up the VPN settings. Afterwards, a Windows folder opens automatically, which contains the file `}<em parentName="p">{`fritzbox `}</em>{` `}{`[...]`}{`. Cfg`}<em parentName="p">{` and a subfolder with the file _vpnuser `}{`_`}{` `}{`[...]`}{`. Cfg`}</em>{`.`}</p>
    <p><strong parentName="p">{`NOTE`}</strong>{`
You can access this folder at any time by selecting the FRITZ! Box name of the FRITZ! Box in FRITZ! Box remote access and clicking on the `}<em parentName="p">{`Explorer`}</em>{` button.`}</p>
    <h3 {...{
      "id": "3-vpn-settings-in-fritzbox-import",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-vpn-settings-in-fritzbox-import",
        "aria-label": "3 vpn settings in fritzbox import permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3 VPN settings in Fritz.Box import`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05a61e89733faafa26b637ff155f4ab8/41870/VPN-Fritzbox-7490de_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVQoz53My07bQBjFce8KEiBg0UU3bHgeHoSn4FZCJSSeAoSqCjZIgKCLdsUCqW2CptyUoIg4CU7sscdjexwnf2Q33EQ38Ek/nU9ncay50jbzq2t8XBPMbghm1gVjS2U+LPxhZCj/Rxd/MbZcZuKzeDS+IphcFUyXBFOlv3z6coZVr5zRqdW4qdbZOrX5+rvNTsVht+KwU37KPdHm4KLLwaVi/zJg/0pzVI35fpNwXIs5rCac1AMs5ThEnkemA1LlQqphYKCfDBnIYjAaTFhIQ8kgUQVyqYJBAr0Q65tQbFYUJ7f50IA0y/VfMGlGlJhCbFJMLyv6rN9HJRkXrRBhhzS6AZaUEmMMvTRGhz6RDl6Jo5x65l9v4oCmK/lx5fDz2uG84WBpHZGfSSKka+N7zf9zh5510m0SeE0iv1UIZQur07lDa0WkJb7XIpDtN/Fz3hPL7dh4XftxLM/3DD+4BzxXQQXWkf3/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05a61e89733faafa26b637ff155f4ab8/e4706/VPN-Fritzbox-7490de_08.avif 230w", "/en/static/05a61e89733faafa26b637ff155f4ab8/d1af7/VPN-Fritzbox-7490de_08.avif 460w", "/en/static/05a61e89733faafa26b637ff155f4ab8/7f308/VPN-Fritzbox-7490de_08.avif 920w", "/en/static/05a61e89733faafa26b637ff155f4ab8/e1c99/VPN-Fritzbox-7490de_08.avif 1380w", "/en/static/05a61e89733faafa26b637ff155f4ab8/a4005/VPN-Fritzbox-7490de_08.avif 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05a61e89733faafa26b637ff155f4ab8/a0b58/VPN-Fritzbox-7490de_08.webp 230w", "/en/static/05a61e89733faafa26b637ff155f4ab8/bc10c/VPN-Fritzbox-7490de_08.webp 460w", "/en/static/05a61e89733faafa26b637ff155f4ab8/966d8/VPN-Fritzbox-7490de_08.webp 920w", "/en/static/05a61e89733faafa26b637ff155f4ab8/445df/VPN-Fritzbox-7490de_08.webp 1380w", "/en/static/05a61e89733faafa26b637ff155f4ab8/d822c/VPN-Fritzbox-7490de_08.webp 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05a61e89733faafa26b637ff155f4ab8/81c8e/VPN-Fritzbox-7490de_08.png 230w", "/en/static/05a61e89733faafa26b637ff155f4ab8/08a84/VPN-Fritzbox-7490de_08.png 460w", "/en/static/05a61e89733faafa26b637ff155f4ab8/c0255/VPN-Fritzbox-7490de_08.png 920w", "/en/static/05a61e89733faafa26b637ff155f4ab8/b1001/VPN-Fritzbox-7490de_08.png 1380w", "/en/static/05a61e89733faafa26b637ff155f4ab8/41870/VPN-Fritzbox-7490de_08.png 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05a61e89733faafa26b637ff155f4ab8/c0255/VPN-Fritzbox-7490de_08.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<em parentName="p">{`Internet`}</em>{` in the FRITZ! Box user interface.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/41870/VPN-Fritzbox-7490de_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZ0lEQVQoz53Oz0rcUBTH8exaQUt10UU33fg8fZA+RbU6Uij0KarQhUIpqKhUXRVaqM7ItaOig2BmppkkNzf3JrlJZr4l6dQ/6KY98OF3+C0Ox3m5+IFXCw2eNY6YfS948U4wOd/k0etDHo9V+8TcDybfNHmyKK5NLQieNgQzS4LppWOevz3CufRCBjql42uWv7t8POiz2vJYa3msNm/ys+iz0fbZOIlZP1Gsnxq2zlN2OhnbFymb5xlfLxVO7PskUlImmjwOIDcwsjDMxiyUKVgDVtdyLRllcY1KHsMog0LjfNoVrHxp863VoZq8HJGXwztsXpJktpbaHFuUdV8Oh8RZSbunEa7mylc4UilsUVAUFqMjEqPuSZNKfMuf3qaKbiDZO/XYP/P4eeXhGJPUn9nMIAOXKOw+LBi71cmgiwq7JFGvpmUPZzD4hTExiZFEYQ8l+/8kqoQ3nGDgEvru9bEq/+fwX78BlgtBEvuR46oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/e4706/VPN-Fritzbox-7490de_09.avif 230w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/d1af7/VPN-Fritzbox-7490de_09.avif 460w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/7f308/VPN-Fritzbox-7490de_09.avif 920w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/e1c99/VPN-Fritzbox-7490de_09.avif 1380w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/a4005/VPN-Fritzbox-7490de_09.avif 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/a0b58/VPN-Fritzbox-7490de_09.webp 230w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/bc10c/VPN-Fritzbox-7490de_09.webp 460w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/966d8/VPN-Fritzbox-7490de_09.webp 920w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/445df/VPN-Fritzbox-7490de_09.webp 1380w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/d822c/VPN-Fritzbox-7490de_09.webp 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/81c8e/VPN-Fritzbox-7490de_09.png 230w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/08a84/VPN-Fritzbox-7490de_09.png 460w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/c0255/VPN-Fritzbox-7490de_09.png 920w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/b1001/VPN-Fritzbox-7490de_09.png 1380w", "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/41870/VPN-Fritzbox-7490de_09.png 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8ea7f2bb8e9f7dea669b9bfe5af2937/c0255/VPN-Fritzbox-7490de_09.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the Internet menu, click `}<em parentName="p">{`Share`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6bbdc23e62c07706c7092ce1b60e688e/41870/VPN-Fritzbox-7490de_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQoz53MzWoTYRjF8dnVhZXahYtu3Hg9Xoj3UCjSNAiCV1EXou2yih9QVxGK1CRM1ZZaAp0kncnMOx/vZ2bmLzPGtlI3+sCP83AWx3vY2ebR5hb3ukMePPO5/9Tn9uM+SxtfuLXQ/CvrPVY3DljuHHGn47eWN31WtnxWuz53u0esPRnijaYzokxyFuU8Pwh4cThlZxCyOwjZ6Yfs9kNeDWZ8+OSz//mEvWPJ6+9Zm29PNe/PDO9+aN6cGnqjDC8vFEpbyrnF5TE4CbWFyixYKDW101RWgS1whaA2eYuGy6E2MC/wXg4F2/2U3qgpa1zZqP5gXYk2FmUs2jrsvGz7sqrITcm3SYEfFJzPMjwhBNZa5s4gixQlsxu0auTX/OqtzhjHgv3jkI8nIV/PQzwpFc1ZoxBxQJqM/y5euNaJeEyWjFHppFWICV4UXSBljpKCNJmQiek/SRvJFS+OApJZcDnW5P8M//YTGixBb6l3JdAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6bbdc23e62c07706c7092ce1b60e688e/e4706/VPN-Fritzbox-7490de_10.avif 230w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/d1af7/VPN-Fritzbox-7490de_10.avif 460w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/7f308/VPN-Fritzbox-7490de_10.avif 920w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/e1c99/VPN-Fritzbox-7490de_10.avif 1380w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/a4005/VPN-Fritzbox-7490de_10.avif 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6bbdc23e62c07706c7092ce1b60e688e/a0b58/VPN-Fritzbox-7490de_10.webp 230w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/bc10c/VPN-Fritzbox-7490de_10.webp 460w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/966d8/VPN-Fritzbox-7490de_10.webp 920w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/445df/VPN-Fritzbox-7490de_10.webp 1380w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/d822c/VPN-Fritzbox-7490de_10.webp 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6bbdc23e62c07706c7092ce1b60e688e/81c8e/VPN-Fritzbox-7490de_10.png 230w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/08a84/VPN-Fritzbox-7490de_10.png 460w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/c0255/VPN-Fritzbox-7490de_10.png 920w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/b1001/VPN-Fritzbox-7490de_10.png 1380w", "/en/static/6bbdc23e62c07706c7092ce1b60e688e/41870/VPN-Fritzbox-7490de_10.png 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6bbdc23e62c07706c7092ce1b60e688e/c0255/VPN-Fritzbox-7490de_10.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click the VPN tab.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/41870/VPN-Fritzbox-7490de_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZElEQVQoz5XMz27TQBDHcd+gUkCUAwcuXHgeHqRPQf9GSEg8RTgglF6Q2gp6KKcekApO5LQlSlBQnZg6ztrrXXttx19kk4ZW7aUjfTSjmdHPerXdYm1rh2fNLi/fObx469DYsHnw+gcPF6p5Zf2ExqbN421n6dGWw5Mdh6dNh9Vmj+dvulgjb4ofKX75kvffXD5892h3LtntXNK2//dPjsf+2ZT9c8neecTeT8XnQcLhMOXLMOFgkHI8irBkrNGJocgNmQwgU1AamKcLBooEjAIT17JYUKayRiWPgepPYX3sClp2yPFIQlmSFZX5DSYr0KmpJSbD5AVZPqcoQcYJZ50+zskpF/0BlhACYwx5lqLiEK2iWxJdkdf825tEMp4KjnouX3sup78nWEppqjKpRgQu4Wx8t2Dh2k4EYyIxQUdeLQ49LN//g1ISrQThbEIkvHsJK7PJkhX4LrOpuwy7Otw3+MpfsK9A/vRBK5cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54571ca7f31fc4ef88b8bf68296bdb16/e4706/VPN-Fritzbox-7490de_11.avif 230w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/d1af7/VPN-Fritzbox-7490de_11.avif 460w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/7f308/VPN-Fritzbox-7490de_11.avif 920w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/e1c99/VPN-Fritzbox-7490de_11.avif 1380w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/a4005/VPN-Fritzbox-7490de_11.avif 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/54571ca7f31fc4ef88b8bf68296bdb16/a0b58/VPN-Fritzbox-7490de_11.webp 230w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/bc10c/VPN-Fritzbox-7490de_11.webp 460w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/966d8/VPN-Fritzbox-7490de_11.webp 920w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/445df/VPN-Fritzbox-7490de_11.webp 1380w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/d822c/VPN-Fritzbox-7490de_11.webp 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54571ca7f31fc4ef88b8bf68296bdb16/81c8e/VPN-Fritzbox-7490de_11.png 230w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/08a84/VPN-Fritzbox-7490de_11.png 460w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/c0255/VPN-Fritzbox-7490de_11.png 920w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/b1001/VPN-Fritzbox-7490de_11.png 1380w", "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/41870/VPN-Fritzbox-7490de_11.png 1788w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/54571ca7f31fc4ef88b8bf68296bdb16/c0255/VPN-Fritzbox-7490de_11.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click Add VPN Connection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/29007/VPN-Fritzbox-7490de_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjElEQVQoz53OzWoUQRDA8X4M32EjiAfvAZ9BH8GzB4WcJYrgTS8eFLyaCAkoCMZT1IiCmGU3E5Y47u7MZJOZ6Z6vna/Mx1+3k1VXctGCH1XdVV20uHT7BSu3bnDh5luWHw64/tTk6qMBF+/16az2WLrbo/On1UVXHhhcvt+jc2eXa4/3EM+293mz854n70Y8/3rMSyNkvatY7wY6r+lasbYrdb1hJGwaic5zs/PmfsrWQCKgAFp0tDlNmdKeoc6hyoH6bGaWm3PUp+/rHPFplPDRDPls50RK4Y3HuJal+bZN5Lq/pGlGUZ6QF6XOWV5SNQ3zOCkyxGsj5FVfsmVmpBOHwhyQjUzykUk2/EZpDSmt71T2ENqWv8P3I/p7Y7qTEkdNEVU+hbbSzTjyiRO1KJZEc6H3W+CSJhLz4ICdD1/YNizMw2NEPE2p6pamhUA6KN8i8O3zyUXKt0miCUXmUcYOSXiE8DyXKAooipRAHhKqf3P6CQclHQJ1hPBdC98d68tZcz70P4vDnwt/ACTViuAXQ3cuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/e4706/VPN-Fritzbox-7490de_12.avif 230w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/d1af7/VPN-Fritzbox-7490de_12.avif 460w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/7f308/VPN-Fritzbox-7490de_12.avif 920w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/e1c99/VPN-Fritzbox-7490de_12.avif 1380w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/2babf/VPN-Fritzbox-7490de_12.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/a0b58/VPN-Fritzbox-7490de_12.webp 230w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/bc10c/VPN-Fritzbox-7490de_12.webp 460w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/966d8/VPN-Fritzbox-7490de_12.webp 920w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/445df/VPN-Fritzbox-7490de_12.webp 1380w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/fad48/VPN-Fritzbox-7490de_12.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/81c8e/VPN-Fritzbox-7490de_12.png 230w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/08a84/VPN-Fritzbox-7490de_12.png 460w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/c0255/VPN-Fritzbox-7490de_12.png 920w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/b1001/VPN-Fritzbox-7490de_12.png 1380w", "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/29007/VPN-Fritzbox-7490de_12.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a3d2265e4b54c5e60af0e2483ab72f1/c0255/VPN-Fritzbox-7490de_12.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<em parentName="p">{`Import a VPN configuration from an existing VPN settings file`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2502992d572308c530608b97ff9f8567/29007/VPN-Fritzbox-7490de_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACJElEQVQoz5WRS27TUBhGvQmkUiGQQGIR7IAtMGLGChgwayskxAoYMOguKghtE5RUhZbm0TYJMXHSOIkTv534ldTXPijuQ4UBEp909P2De4/uQ6qcNCk1+hzIBj96FseKyWHXpCwbVGSTSt43858c/DL5pliUzoYUahfUlCnSm9cvefzqI/c2Gjzf7vPsQ5en75usbdZZ31rRYH2zzv2/WNuo82CrzpN3pzx62+DhVo0X2+dI1e6Ammpzougcfj+jctTkuGdxOvY5n4ScT4K8O9YS2b7M6dpJzi87Qb5uxU3pTl0kLmPyiBhGQzANWF7CYgFZxv9kGc2RCm2LXTmgOp1Q9rt8DXrsOz/ZtZrs2S1KnkzR61B0O3xx2pz6Q8ggzbKcuwmDOVKpY1HpxxyNVApalZ1xjU9mi12nQ8FsUbTb7Ntt9swWO9MG5am8siHSlJW5ocWU+yGHaoTheEjZ9ZVX9tCLEbZLonQR/QuY+WSxIIsSSPL9REGAY0+YeQa+Z9BWNarKiFpvhGHpSPFimQvn4QyRZfgzl6Vt5IgoQGQpSSpIhFj58nW2qeI5Y1x7TOBpRDONyBszc3Ukx7FYLCLmvnt10jD858MH4RzbHOI5Wo5r3zDGc3Qky1Cx9AGOq+efuhKKRJCKG1KEECwXi7xnvncrvJJod2YdydAHmPqAyVRFN3VG2ihv0zYxLAPTsdAnYzrFz3TOGihq71Z0V+hdC38DrqQe/xA/aKgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2502992d572308c530608b97ff9f8567/e4706/VPN-Fritzbox-7490de_13.avif 230w", "/en/static/2502992d572308c530608b97ff9f8567/d1af7/VPN-Fritzbox-7490de_13.avif 460w", "/en/static/2502992d572308c530608b97ff9f8567/7f308/VPN-Fritzbox-7490de_13.avif 920w", "/en/static/2502992d572308c530608b97ff9f8567/e1c99/VPN-Fritzbox-7490de_13.avif 1380w", "/en/static/2502992d572308c530608b97ff9f8567/2babf/VPN-Fritzbox-7490de_13.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2502992d572308c530608b97ff9f8567/a0b58/VPN-Fritzbox-7490de_13.webp 230w", "/en/static/2502992d572308c530608b97ff9f8567/bc10c/VPN-Fritzbox-7490de_13.webp 460w", "/en/static/2502992d572308c530608b97ff9f8567/966d8/VPN-Fritzbox-7490de_13.webp 920w", "/en/static/2502992d572308c530608b97ff9f8567/445df/VPN-Fritzbox-7490de_13.webp 1380w", "/en/static/2502992d572308c530608b97ff9f8567/fad48/VPN-Fritzbox-7490de_13.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2502992d572308c530608b97ff9f8567/81c8e/VPN-Fritzbox-7490de_13.png 230w", "/en/static/2502992d572308c530608b97ff9f8567/08a84/VPN-Fritzbox-7490de_13.png 460w", "/en/static/2502992d572308c530608b97ff9f8567/c0255/VPN-Fritzbox-7490de_13.png 920w", "/en/static/2502992d572308c530608b97ff9f8567/b1001/VPN-Fritzbox-7490de_13.png 1380w", "/en/static/2502992d572308c530608b97ff9f8567/29007/VPN-Fritzbox-7490de_13.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2502992d572308c530608b97ff9f8567/c0255/VPN-Fritzbox-7490de_13.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<em parentName="p">{`Select File`}</em>{`.`}</p>
    <p>{`Select the `}<em parentName="p">{`fritzbox `}</em>{` `}{`[...]`}{`. Cfg`}<em parentName="p">{` created with FRITZ! Box remote access setup and click on _open`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/069e7cf549729b41709ba1b6897f0b50/de5f1/VPN-Fritzbox-7490de_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABU0lEQVQoz52PzS4DURiGj4UdV+EOXI2NGxAJYiP2lt0SEcUFNBILcQGq1VZJiIidmBnM/485PfPTeaSjbRqr1ps8+c73c77zHrG2voJYrbG422a58sjS3gMLOy3ERh2xdYPYnGBQ+8Pizi3z240yzm1eI84vL6lcvbB/bXDasjiof3LYsDhuORw1bY6aVsnJnc9ZN+C0G5RxRLXjD3shx80vBCNl3/imThI6FL0AkhBIh82C6aQQmh3xESgi2SPvF2T9ApWmSKVI0mxcK8knGOb9ibeUkoiTtk217WCGaiYvI8kkxwoT7LjA/5aIRPXKNTIOkTKamjiOyNOY56dXahd1ah2N+zcTkeU5RVEQeF+49juurc1E6GpIT0P6OpGnI3rq96uhb+I5Or5rzITnGrjOL55jIGzLIPDMMvEdvRyYCme4cHBnjIEYHAbWR+7+43KSHwTf031gJX4KAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/069e7cf549729b41709ba1b6897f0b50/e4706/VPN-Fritzbox-7490de_14.avif 230w", "/en/static/069e7cf549729b41709ba1b6897f0b50/d1af7/VPN-Fritzbox-7490de_14.avif 460w", "/en/static/069e7cf549729b41709ba1b6897f0b50/7f308/VPN-Fritzbox-7490de_14.avif 920w", "/en/static/069e7cf549729b41709ba1b6897f0b50/e1c99/VPN-Fritzbox-7490de_14.avif 1380w", "/en/static/069e7cf549729b41709ba1b6897f0b50/2653f/VPN-Fritzbox-7490de_14.avif 1581w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/069e7cf549729b41709ba1b6897f0b50/a0b58/VPN-Fritzbox-7490de_14.webp 230w", "/en/static/069e7cf549729b41709ba1b6897f0b50/bc10c/VPN-Fritzbox-7490de_14.webp 460w", "/en/static/069e7cf549729b41709ba1b6897f0b50/966d8/VPN-Fritzbox-7490de_14.webp 920w", "/en/static/069e7cf549729b41709ba1b6897f0b50/445df/VPN-Fritzbox-7490de_14.webp 1380w", "/en/static/069e7cf549729b41709ba1b6897f0b50/2351a/VPN-Fritzbox-7490de_14.webp 1581w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/069e7cf549729b41709ba1b6897f0b50/81c8e/VPN-Fritzbox-7490de_14.png 230w", "/en/static/069e7cf549729b41709ba1b6897f0b50/08a84/VPN-Fritzbox-7490de_14.png 460w", "/en/static/069e7cf549729b41709ba1b6897f0b50/c0255/VPN-Fritzbox-7490de_14.png 920w", "/en/static/069e7cf549729b41709ba1b6897f0b50/b1001/VPN-Fritzbox-7490de_14.png 1380w", "/en/static/069e7cf549729b41709ba1b6897f0b50/de5f1/VPN-Fritzbox-7490de_14.png 1581w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/069e7cf549729b41709ba1b6897f0b50/c0255/VPN-Fritzbox-7490de_14.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<em parentName="p">{`OK`}</em>{`.`}</p>
    <h3 {...{
      "id": "4-vpn-settings-in-fritz-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-vpn-settings-in-fritz-remote-access",
        "aria-label": "4 vpn settings in fritz remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4 VPN settings in FRITZ! Remote access`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca147ac42a85b1f8519926948f7a861f/fc2a6/VPN-Fritzbox-7490de_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB8klEQVQoz53S3WoTQRTA8bn3FS2CF1J9F19D8EIvTFoQRfFK22ybmO9kd5tsNul+zszZbLaWFvnLJkEbEAQvfpwzZ4bhzGGUah6hGo9R75+wzet4eoT69BTlHKPOj1Gtf3mOOnvGoy8vUG/CM06WFzTmLZqzFs2FQzNo0Qgdmkmbk6RDM24faMSXvLu+ONCILnkbfEMVVqgyocyFdWYoUkOZWSotSJSjw5gytWwyyya1lKmhyoX74oa7ouKujlLxUypsrlHLPMb1fDrdLv3hgOFkwnA0Yuq5TD0f1/cZTSZ0BwP6wyG9fh/v6gotFm3NVl4I9uaW0Oao1OZsyvK3slzvrPf264Mz6wIRi1iLrNfIcsHm62eivoNaZCmJLohz2dE7yQPbWv7Hrl6Q5JZ4c4u+bLF5/ZLo9BVqEqXY6h5b3f2fm3tsUVHOA/zZDBWbHJECY+WAfsBYuyd/pUWwmx8sdYaKdIrRGq1zdJ5jdI5YTSFCIXZPKOrBW4s1ZpuLCMYYjNHbeRbWssqiusOM+uvUh+uNTFtGQUJnfMX3aUDPWzCdThiPJ8znc1arFa7r4vs+URSRJMk2DxchQbTcPbnuoL6wjpkWHDfi4/mAD60xreGMXq9Lp9PB8zwWQYDjOLTbHcLlkjiO6ff6eK7H/DrkF5bcDnHE1a3YAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca147ac42a85b1f8519926948f7a861f/e4706/VPN-Fritzbox-7490de_15.avif 230w", "/en/static/ca147ac42a85b1f8519926948f7a861f/e46ac/VPN-Fritzbox-7490de_15.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca147ac42a85b1f8519926948f7a861f/a0b58/VPN-Fritzbox-7490de_15.webp 230w", "/en/static/ca147ac42a85b1f8519926948f7a861f/8626f/VPN-Fritzbox-7490de_15.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca147ac42a85b1f8519926948f7a861f/81c8e/VPN-Fritzbox-7490de_15.png 230w", "/en/static/ca147ac42a85b1f8519926948f7a861f/fc2a6/VPN-Fritzbox-7490de_15.png 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca147ac42a85b1f8519926948f7a861f/fc2a6/VPN-Fritzbox-7490de_15.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the FRITZ! Remote Access application from the Windows Start menu and click on the Explorer icon in the program.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "558px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d2f7b13ecd89d237f1050ba39d752490/42a8d/VPN-Fritzbox-7490de_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACnklEQVQ4y5WRTY/aVhSGvcpk5m92iybTH1JV3UXqpqq66CaRMq1UJWkX6YSBDMNAqjEGbGxfwNgGf38AM2A/EZ4kgipR1SM9Ove8555X5+pKR398w0m9xnHnjEetU45vnnDSOXvIN2c8vj7lUavGUatW5cftU04633LU3vGE4/YpRzve1Thu1JAGM4NWv0tX9NHmba70Hk1Npi0GvBv1UBYTjNRDT+bosYs8M/hbbiLP6rTNPk1hoAcWmmdRdxSkJFkinJhVFOMOZOLxhHvP497zWc8XbIMQ0hTiBLKczeoeW4xZWCYzc0zkxaySlO3yDiv3kQYTlwszIBveEjz/BffVOcFfv+O9Pmfx8gXeq3O8P3/DefEr+cvnlIDvLbBmDlNrxvpuTZKm2NaMW9dA6o/GdNUxvjAojSEbMeLeVPfQHrRRn0JoleF2u/1MUZZsi4Jis0VPHKRn/ZQf2jEUazb8d5RleVjvaSJfILnWGEsY1dplsYWioPwKVa98MNin+GSYLZDmCwVht1nmdxS7oS9ssa99MtnXDwyVfh+53yPwg4OBL8FX6gPDqZ9R10PuVvnnxv+JyvDjy0Q2RxKGSU81yNK0auzY/8V/s9k7V5t+/JhdmKsAaWh5XGlT1suM6XSKruuYpolhmggxxhTiALGHH4akc5dQGxCqQxS1g3Q9GPO6M2SZRvR6Cpo2Io4ihONjBTnzeIUbr3HjFU60xA7zipmfMQlzosYbbp9+j/zTjyg/f4cU+x7RjihCURQmkylZljHSdS4u6lxeNmk0mrx9e8lV65r2TYdu9z23sszMdvAcm07jkn+aTW7U90h5npNlOUmSEIZhRRzH2LaNOhyi9BRUVWUwGOA4DkEQEPh+lav7UYQXhARhhOJP+QA+sktOGUx0ggAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2f7b13ecd89d237f1050ba39d752490/e4706/VPN-Fritzbox-7490de_16.avif 230w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/d1af7/VPN-Fritzbox-7490de_16.avif 460w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/f9e70/VPN-Fritzbox-7490de_16.avif 558w"],
              "sizes": "(max-width: 558px) 100vw, 558px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d2f7b13ecd89d237f1050ba39d752490/a0b58/VPN-Fritzbox-7490de_16.webp 230w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/bc10c/VPN-Fritzbox-7490de_16.webp 460w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/1c397/VPN-Fritzbox-7490de_16.webp 558w"],
              "sizes": "(max-width: 558px) 100vw, 558px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2f7b13ecd89d237f1050ba39d752490/81c8e/VPN-Fritzbox-7490de_16.png 230w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/08a84/VPN-Fritzbox-7490de_16.png 460w", "/en/static/d2f7b13ecd89d237f1050ba39d752490/42a8d/VPN-Fritzbox-7490de_16.png 558w"],
              "sizes": "(max-width: 558px) 100vw, 558px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d2f7b13ecd89d237f1050ba39d752490/42a8d/VPN-Fritzbox-7490de_16.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the file `}<em parentName="p">{`vpnuser `}{`_`}{` `}{`[...]`}{`. Cfg`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "451px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/805184f3b9c78e951955150f8990bc8c/38070/VPN-Fritzbox-7490de_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFklEQVQoz33Rz2vTcBjH8RzW3fY3qohetn9DEBRv3jwpzO68MRCH4HSb25zY323SLEnbpEma5PsjSSc4fEvTYreLhxfP5/s8Dwl8v8bW4RM29h+w+eExtYOH1Bb18BG1T0+pXe5Qu9he1v/YvNxh42Kbrc/bGO+cY/ZGp9SdE+r2V+ruyTJ7p9Sn5+xNz6kH39ZW513/7J73/hlv3S8YWkhuUk2ZSIpEkseiyvNUk4cZ0o8pY0k5W4kFN4niVt1wq+aV32rOH/0LLRTGeDbFGto0Wk2anQ7tbpduv8fAsuhbVlV75oBmu13NFtUcDklEdk8mBJMkxAizGfOioCwLymKtKPKVVe/OvMhzlJQotZYrSZjGGKNZRBCljKcx43DGeLo2qc4xnh8xCuJVL6kEcbrKM/w4w3M7OFMPox+E9OwJHculOxzRNh1aA4fmwKFjeVV/kRezgTOhbbpV33QDukOP/vWE/vWYjunS8iYYQTpDCoWQcklIsjuEEMhVXUiztWouVZWl1ARJjDFNY9RiOcsQYklKidYarRSynKPKObqcV1lqTb6Q62pPCoHWqupVdxgkEVmakabpP0EQcG3b2K5LdPyRycd9vKNDwqMDArOHadtYloXv+4RhiG3buI6LN50sX1lLtfzbymKx1WjQ6vUZ777h+4tnnL56jvX6JUHjisufDX5cXTEajYmiiHarxaBvVh/8C3ixBxfh1gpcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/805184f3b9c78e951955150f8990bc8c/e4706/VPN-Fritzbox-7490de_17.avif 230w", "/en/static/805184f3b9c78e951955150f8990bc8c/11b19/VPN-Fritzbox-7490de_17.avif 451w"],
              "sizes": "(max-width: 451px) 100vw, 451px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/805184f3b9c78e951955150f8990bc8c/a0b58/VPN-Fritzbox-7490de_17.webp 230w", "/en/static/805184f3b9c78e951955150f8990bc8c/8f864/VPN-Fritzbox-7490de_17.webp 451w"],
              "sizes": "(max-width: 451px) 100vw, 451px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/805184f3b9c78e951955150f8990bc8c/81c8e/VPN-Fritzbox-7490de_17.png 230w", "/en/static/805184f3b9c78e951955150f8990bc8c/38070/VPN-Fritzbox-7490de_17.png 451w"],
              "sizes": "(max-width: 451px) 100vw, 451px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/805184f3b9c78e951955150f8990bc8c/38070/VPN-Fritzbox-7490de_17.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<em parentName="p">{`Open`}</em>{` and `}<em parentName="p">{`Finish`}</em>{`.`}</p>
    <h3 {...{
      "id": "5-vpn-connection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-vpn-connection",
        "aria-label": "5 vpn connection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5 VPN connection`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "485px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/44c61/VPN-Fritzbox-7490de_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABoUlEQVQ4y62Q0Y7SQBiF+xAbSHwDn029ciNeeOkb+BB7oy+wyWY30WhYjC6wG0phCyloaWmHtjOlU9rwGcpKJOFCVs/kZM7J+XMy8xtPW295cvWSk/Nn1C9eUDt/Tu36lHrnNfV2Y4+1nX7Ivr2iftPY8nuD+vUpRtO+pesOaNldzHCKswxwspCR8rGlx70KsJVf+XE6r+6R8rBV8KD3aeRJTNOKaFqCIpVMxxOsux5ZkhK4M/qtC6QQpLHEGU2Yux5pognHVyS+jYpS5CJCLuKKxiISfL4ZMBm5LBPJ0LbpmSae72GafVqXZwyHPWaeT7vTxZlMEFGM1fuK44wJFwvmQcA8DAjCECOJEn6KGR+cL5jxD0pdkGnNcrlECEEoEsJQ7LxSijzPiSKJlFu9mdd5XnmjVBoLwcnHN7ybXrJByZrHYFNqKKlwhc978xOd6RDKNdVZH8cNskxjqDTFc2cM2nfc9wcUZVGFv4f+BrtCrTGklNvnrvK9skcXJok8GB6Dg4XH7uzgDv/88v+A1jlGFMUUZclqtfonFkWBUim/AIC8zWC6ySWOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/e4706/VPN-Fritzbox-7490de_18.avif 230w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/d1af7/VPN-Fritzbox-7490de_18.avif 460w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/b3175/VPN-Fritzbox-7490de_18.avif 485w"],
              "sizes": "(max-width: 485px) 100vw, 485px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/a0b58/VPN-Fritzbox-7490de_18.webp 230w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/bc10c/VPN-Fritzbox-7490de_18.webp 460w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/749af/VPN-Fritzbox-7490de_18.webp 485w"],
              "sizes": "(max-width: 485px) 100vw, 485px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/81c8e/VPN-Fritzbox-7490de_18.png 230w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/08a84/VPN-Fritzbox-7490de_18.png 460w", "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/44c61/VPN-Fritzbox-7490de_18.png 485w"],
              "sizes": "(max-width: 485px) 100vw, 485px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ceb3c3d36e26b96c0c0567eabd9bd560/44c61/VPN-Fritzbox-7490de_18.png",
              "alt": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "title": "VPN Verbindung zu Ihrem AVM Fritzbox Router",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`From the computer with FRITZ! Remote access, establish an Internet connection and call the program Fritz! Remote access. Click the DDNS name icon, and then click Design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      